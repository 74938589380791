import { useLocation, useSearchParams } from "@remix-run/react";
import { createContext, useContext, useEffect, useState } from "react";
import type { FacebookEventName } from "~/types";
import { v4 as uuid } from "uuid";
import { ENABLE_TRACKING, GA_TRACKING_ID } from "~/lib/env";
import { useDebouncedValue } from "@mantine/hooks";
import { trpc } from "~/lib/trpc";

interface TrackingEngineProps {
  deviceId: string;
  children: React.ReactNode;
}

interface TrackingEngingContextProps {
  externalId: string;
  setExternalId: (val: string) => void;
  trackMixpanel: (name: string, props?: Record<string, string>) => void;
  trackIntercom: (name: string, props?: Record<string, string>) => void;
  trackGoogle: (name: string, props?: Record<string, string>) => void;
  peopleMixpanel: (id: string, props: Record<string, string>) => void;
  trackFacebook: (
    name: FacebookEventName,
    props?: Record<string, string>
  ) => void;
}

export const TrackingEngineContext = createContext<TrackingEngingContextProps>(
  {} as TrackingEngingContextProps
);
type TrackingLayer = {
  provider: "mp" | "fb";
  [k: string]: any;
}[];

export const TrackingEngineProvider = ({
  children,
  deviceId,
}: TrackingEngineProps) => {
  const location = useLocation();
  const [externalId, setExternalId] = useState(deviceId);
  const [searchParams] = useSearchParams();
  const [trackLayer, setTrackLayer] = useState<TrackingLayer>([]);
  const [debounced] = useDebouncedValue(trackLayer, 500);
  const { mutate } = trpc.tracking.internal.useMutation({
    onSuccess() {
      setTrackLayer([]);
    },
  });
  const people = trpc.tracking.people.useMutation();

  useEffect(() => {
    if (!ENABLE_TRACKING) {
      return;
    }

    if (debounced.length === 0) {
      return;
    }

    mutate(debounced);
  }, [debounced, mutate]);

  const trackIntercom = (name: string, metadata: Record<string, any> = {}) => {
    if (!ENABLE_TRACKING) {
      return;
    }

    if ("Intercom" in window) {
      //
      window.Intercom("trackEvent", name, metadata);
    }
  };

  const trackMixpanel = async (
    name: string,
    props: Record<string, string> = {}
  ) => {
    if (!ENABLE_TRACKING) {
      console.log("[trackMixpanel]", "TRACKING NOT ENABLED");
      return;
    }

    trackIntercom(name, props);
    setTrackLayer((prev) => [
      ...prev,
      {
        provider: "mp",
        name,
        page_name: window.location.pathname,
        $device_id: deviceId,
        $referrer: document.referrer,
        $referring_domain: document.referrer
          ? new URL(document.referrer).hostname
          : "",
        url: window.location.href,
        search: window.location.search,
        path: window.location.pathname,
        title: document.querySelector("title")?.innerText,
        ...props,
        ...Object.fromEntries(searchParams),
      },
    ]);
  };

  const peopleMixpanel = async (id: string, props: Record<string, string>) => {
    if (!ENABLE_TRACKING) {
      console.log("[peopleMixpanel]", "TRACKING NOT ENABLED");
      return;
    }

    people.mutate({ id, ...props });
  };

  const trackFacebook = async (
    name: FacebookEventName,
    props: Record<string, string> = {}
  ) => {
    if (!ENABLE_TRACKING) {
      console.log("[trackFacebook]", "TRACKING NOT ENABLED");
      return;
    }

    if (!("fbq" in window)) {
      return;
    }

    const eventID = uuid();
    window.fbq("track", name, { ...props }, { eventID });

    const search = Object.fromEntries(searchParams);
    if (search.latitude) delete search.latitude;
    if (search.longitude) delete search.longitude;

    setTrackLayer((prev) => [
      ...prev,
      {
        provider: "fb",
        name,
        page_name: window.location.pathname,
        eventID,
        ...Object.fromEntries(searchParams),
        ...props,
      },
    ]);
  };

  const trackGoogle = (name: string, props: Record<string, string> = {}) => {
    if (!ENABLE_TRACKING) {
      console.log("[trackGoogle]", "TRACKING NOT ENABLED");
      return;
    }

    if (!window.gtag) {
      console.warn(
        "window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet."
      );
      return;
    }
    window.gtag("event", name, {
      ...props,
    });
  };

  useEffect(() => {
    if (ENABLE_TRACKING) {
      trackFacebook("PageView");
      trackMixpanel("Viewed Page");
      window.gtag("config", GA_TRACKING_ID, {
        page_path: location.pathname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <TrackingEngineContext.Provider
      value={{
        externalId,
        setExternalId,
        trackIntercom,
        trackMixpanel,
        trackFacebook,
        trackGoogle,
        peopleMixpanel,
      }}
    >
      {children}
    </TrackingEngineContext.Provider>
  );
};

export const useTrackingEngine = () => {
  return useContext(TrackingEngineContext);
};
